

































































































































import Confirm from '@/components/confirmation/Confirmation.vue';
import JobDurationText from '@/components/job-duration-text/JobDurationText.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';
import { EntityType } from '@/module/api/domo/five9-entities.interface';
import { CrawlJobInterface, ExportModel, ExportStateName, ObjectSummaryItem, useExportApi } from '@/module/api/export';
import router from '@/router';
import { formatDbDate, toNum } from '@/utils';
import CrawlJobDialog, { useCrawlJobDialog } from '@/views/app/export/CrawlJobDialog.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';

const { selectedItem: selectedDomain, refreshItem: refreshDomain, isLoading: isDomainLoading } = useDomainApi();

const { show: showCrawlJobDialog } = useCrawlJobDialog();

const {
  items: exportJobs,
  isLoading: isExportLoading,
  runDownloadJobReport,
  refreshItem: refreshExportJob,
  selectedItem: selectedExport,
  getCrawlJobs,
  runDownloadJobErrorReport,
} = useExportApi();

const state = reactive({
  domainUuid: '',
  exportJobUuid: '',
  backLink: '',
  isDownloadingReport: false,
  isSimpleTable: false,
  crawlJobs: ref<CrawlJobInterface[]>([]),
});

const getObjectSummaryItems = (): ObjectSummaryItem[] => {
  if (!selectedExport?.value?.objectSummary?.totalByType || state.crawlJobs.length < 1) {
    return [];
  }
  // TODO: look up crawl jobs to find if we have any errors
  const totalByType = selectedExport?.value?.objectSummary?.totalByType;
  return Object.keys(totalByType).map((entityType: string) => {
    const count = totalByType[entityType as EntityType] ?? 0;
    let errors = 0;
    let crawlJobUuid = '';
    for (const crawlJob of state.crawlJobs) {
      if (crawlJob.entityType === entityType) {
        crawlJobUuid = crawlJob.uuid;
        if (crawlJob.stats.totalsByState.ERRORED) {
          errors = crawlJob.stats.totalsByState.ERRORED;
        } else {
          break;
        }
      }
    }
    return {
      extractJobUuid: selectedExport?.value?.uuid ?? '',
      entityType: entityType,
      crawlJobUuid: crawlJobUuid,
      count: count,
      errors: errors,
    };
  });
};

const objectSummaryHeaders = [
  {
    text: 'Type',
    value: 'entityType',
    width: 400,
  },
  {
    text: 'Object Count',
    value: 'count',
    width: 100,
    align: 'right',
  },
  {
    text: 'Action',
    value: 'errors',
    width: 100,
    align: 'center',
  },
];

const objectSummary = computed(() => {
  const items = getObjectSummaryItems();
  if (isDomainLoading.value || selectedExport?.value === undefined || items.length < 1) {
    return {
      headers: objectSummaryHeaders,
      items: [],
      totalErrors: 0,
      total: 0,
    };
  }
  let totalErrors = 0;
  for (const item of items) {
    if (item.errors) {
      totalErrors = totalErrors + item.errors;
    }
  }
  return {
    headers: objectSummaryHeaders,
    items: items,
    totalErrors: totalErrors,
    total: selectedExport?.value?.objectSummary?.total ?? 0,
  };
});

// Pass
const goToDomainObjectBrowserPage = (extractJobUuid?: string) => {
  if (!extractJobUuid) {
    return;
  }
  router.push({
    name: 'DomainObjectBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: extractJobUuid,
    },
  });
};

const getObjectCount = (item: ExportModel): string => {
  const total = toNum(item?.objectSummary?.total);
  return total.toLocaleString('en-us');
};

const initializeData = async (forceRefresh = false) => {
  Vue.$log.debug('Initializing data');
  await refreshDomain(state.domainUuid, forceRefresh);
  await refreshExportJob(state.exportJobUuid, forceRefresh);
  state.crawlJobs = (await getCrawlJobs(state.exportJobUuid)) ?? [];
};

const refreshItems = () => {
  initializeData(true);
};

const reset = async () => {
  return;
};

export default defineComponent({
  name: 'ExportJobDetail',
  components: {
    RefreshButton,
    Confirm,
    DomainWorkspaceToolbar,
    JobStatusIcon,
    JobDurationText,
    CrawlJobDialog,
  },
  props: {
    uuid: {
      type: String,
    },
    jobUuid: {
      type: String,
    },
    overrideBackLink: {
      type: String,
    },
  },
  setup(props) {
    state.domainUuid = props?.uuid || '';
    state.exportJobUuid = props?.jobUuid || '';
    state.backLink = props?.overrideBackLink || `/domain/${props.uuid}/workspace`;
    initializeData();
    return {
      ...toRefs(state),
      isLoading: computed(() => isExportLoading.value || isDomainLoading.value),
      reset,
      formatDbDate,
      refreshItems,
      selectedDomain,
      selectedExport,
      exportJobs,
      isExportLoading,
      isModelExpired,
      DOMO_EXPIRATION_TTL_DAYS,
      ExportStateName,
      objectSummary,
      goToDomainObjectBrowserPage,
      getObjectCount,
      runDownloadJobReport,
      runDownloadJobErrorReport,
      showCrawlJobDialog,
      isDownloadJobErrorReportDisabled: computed(() => {
        if (isExportLoading.value || isDomainLoading.value) {
          return true;
        }
        if (selectedExport.value.state === ExportStateName.IN_PROGRESS) {
          return true;
        }
        if (isModelExpired(selectedExport.value)) {
          return true;
        }
        return false;
      }),
    };
  },
});
