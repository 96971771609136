var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: { backLink: _vm.backLink, subtitle: "Snapshot" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("refresh-button", {
                          staticClass: "mr-2",
                          attrs: { refreshFunction: _vm.refreshItems }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-title",
            [
              _c("job-status-icon", {
                attrs: { job: _vm.selectedExport, suffix: "Object Summary" }
              })
            ],
            1
          ),
          _vm.selectedExport.updatedAt
            ? _c(
                "v-card-subtitle",
                [
                  _vm._v(
                    "Last Updated at " +
                      _vm._s(_vm.formatDbDate(_vm.selectedExport.updatedAt)) +
                      " "
                  ),
                  _c("job-duration-text", {
                    attrs: { job: _vm.selectedExport }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            [
              !_vm.selectedExport.state
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "warning",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [
                      _vm._v(
                        ' There are no objects for this domain. Click "New Snapshot" to get started. '
                      )
                    ]
                  )
                : _vm.selectedExport.state === _vm.ExportStateName.IN_PROGRESS
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "", size: "100" } },
                        [
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: 270,
                                size: 100,
                                width: 15,
                                value: _vm.extractJobPercentComplete,
                                color: "blue darken-3"
                              }
                            },
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(
                                  _vm._s(_vm.extractJobPercentComplete) + "%"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "title mb-1" },
                            [_vm._v(" Snapshot in progress...")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.selectedExport.state === _vm.ExportStateName.CANCELLED
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "warning",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [_vm._v(" The snapshot was cancelled. ")]
                  )
                : _c(
                    "div",
                    [
                      _vm.selectedExport.state === _vm.ExportStateName.ERRORED
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "elevation-2",
                              attrs: {
                                type: "error",
                                "colored-border": "",
                                border: "top"
                              }
                            },
                            [
                              _vm._v(
                                " The snapshot failed which means one or more objects failed to be extracted from the Domain. Please review each object type for errors. After addressing any problems if the issues persist content support. "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.objectSummary.headers,
                            items: _vm.objectSummary.items,
                            "items-per-page": 8,
                            "sort-by": ["errors", "count"],
                            "sort-desc": [true, true],
                            dense: ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.errors",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.errors,
                                          expression: "item.errors"
                                        }
                                      ],
                                      staticClass: "ml-4"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.showCrawlJobDialog(
                                                item,
                                                _vm.selectedExport
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("View Errors")]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("template", { slot: "body.append" }, [
                            _c("tr", { staticClass: "font-weight-bold" }, [
                              _c("td", [_vm._v("TOTAL")]),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [_vm._v(_vm._s(_vm.objectSummary.total))]
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
              _vm.isModelExpired(_vm.selectedExport)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "elevation-2",
                          attrs: {
                            type: "error",
                            "colored-border": "",
                            border: "top"
                          }
                        },
                        [
                          _c("b", [_vm._v("EXPIRED")]),
                          _vm._v(
                            ": This Snapshot is no longer available because it is older than " +
                              _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
                              " days. You must take a new Snapshot. "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent-4",
                    plain: "",
                    small: "",
                    disabled:
                      _vm.selectedExport.state ===
                        _vm.ExportStateName.IN_PROGRESS ||
                      _vm.isModelExpired(_vm.selectedExport)
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToDomainObjectBrowserPage(
                        _vm.selectedExport.uuid
                      )
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("mdi-magnify")]),
                  _vm._v(" Browse Objects ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent-4",
                    title: "Download object report",
                    plain: "",
                    small: "",
                    disabled:
                      _vm.isLoading ||
                      _vm.selectedExport.state ===
                        _vm.ExportStateName.IN_PROGRESS ||
                      _vm.isModelExpired(_vm.selectedExport)
                  },
                  on: {
                    click: function($event) {
                      return _vm.runDownloadJobReport(_vm.selectedExport.uuid)
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("mdi-file-excel")]),
                  _vm._v(" Download Object Report ")
                ],
                1
              ),
              _vm.selectedExport.state === _vm.ExportStateName.IN_PROGRESS
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        text: "",
                        small: "",
                        disabled:
                          _vm.isCanceling ||
                          _vm.isModelExpired(_vm.selectedExport)
                      },
                      on: { click: _vm.runCancelExtractJob }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close-circle")
                      ]),
                      _vm._v(" Cancel ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("confirm"),
      _c("crawl-job-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }