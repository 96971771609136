var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: { "retain-focus": false, width: "45vw", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "no-x", attrs: { loading: _vm.isLoading } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(
                    "Errors extracting " + _vm._s(_vm.summaryItem.entityType)
                  )
                ])
              ]),
              _c("v-card-subtitle", { staticClass: "mt-1" }, [
                _vm._v(_vm._s(_vm.exportJob.label))
              ]),
              _c(
                "v-card-text",
                { staticClass: "no-x", staticStyle: { height: "40vh" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.errorSummary.headers,
                      items: _vm.errorSummary.items,
                      "items-per-page": 10,
                      "sort-by": "statusCode",
                      dense: "",
                      "show-expand": "",
                      "item-key": "url",
                      loading: _vm.isLoading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "expanded-item",
                          fn: function(ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c("td", { attrs: { colspan: headers.length } }, [
                                _vm._v(
                                  "Detail: " + _vm._s(item.detail || "None")
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2632926921
                    )
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "accent-4",
                        title: "Download error report",
                        small: "",
                        text: "",
                        disabled: _vm.isDownloadJobErrorReportDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.runDownloadJobErrorReport(
                            _vm.summaryItem.extractJobUuid,
                            _vm.summaryItem.crawlJobUuid,
                            _vm.summaryItem.entityType
                          )
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-file-excel")]),
                      _vm._v(" Download ")
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }