var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isExpired
      ? _c("div", [
          _vm._v(
            "Expired: Older than " +
              _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
              " days"
          )
        ])
      : _vm.expiresIn
      ? _c("div", [_vm._v("Expires: " + _vm._s(_vm.expiresIn))])
      : _vm._e(),
    _vm.duration
      ? _c("div", { attrs: { title: _vm.titleText } }, [
          _vm._v("Duration: " + _vm._s(_vm.duration))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }