





















































import { useDomainApi } from '@/module/api/domain';
import { isModelExpired } from '@/module/api/domo';
import {
  CrawlJobRequestReport,
  ExportModel,
  ExportStateName,
  ObjectSummaryItem,
  emptyItem as emptyExportJob,
  useExportApi,
} from '@/module/api/export';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface({ maxToasts: 3 });

const { emptyDomainModel, isLoading: isDomainLoading } = useDomainApi();

const { getJobErrorReport, runDownloadJobErrorReport, isLoading: isExportLoading } = useExportApi();

const emptyState = () => {
  return {
    dialog: false,
    domain: emptyDomainModel,
    exportJob: emptyExportJob(),
    summaryItem: ref<ObjectSummaryItem>({
      count: 0,
      crawlJobUuid: '',
      entityType: '',
      errors: 0,
      extractJobUuid: '',
    }),
    isLoading: false,
    entityType: '',
    errors: ref<CrawlJobRequestReport[]>([]),
  };
};

const state = reactive({ ...emptyState() });

const errorSummary = computed(() => {
  if (!state.errors || state.errors.length < 1) {
    return {
      headers: [],
      items: [],
    };
  }
  const firstRow = state.errors[0];
  const headers: Record<string, string>[] = [];
  for (const key in firstRow) {
    if (key === 'detail') {
      continue;
    }
    headers.push({
      text: key,
      value: key,
    });
  }
  return {
    headers: headers,
    items: state.errors,
  };
});

const show = async (summaryItem: ObjectSummaryItem, exportJob: ExportModel): Promise<void> => {
  state.dialog = true;
  state.exportJob = exportJob;
  state.summaryItem = summaryItem;
  state.isLoading = true;
  // Reset the state first
  state.errors = [];
  try {
    state.errors = (await getJobErrorReport(summaryItem.extractJobUuid, summaryItem.crawlJobUuid)) ?? [];
  } catch (e) {
    // Hopefully we already have an alert.
  } finally {
    state.isLoading = false;
  }
};

export function useCrawlJobDialog() {
  return {
    show,
  };
}

export default defineComponent({
  name: 'CrawlJobDialog',
  setup() {
    return {
      ...toRefs(state),
      errorSummary,
      runDownloadJobErrorReport,
      isDownloadJobErrorReportDisabled: computed(() => {
        if (state.isLoading) {
          return true;
        }
        if (isExportLoading.value || isDomainLoading.value) {
          return true;
        }
        if (state.exportJob.state === ExportStateName.IN_PROGRESS) {
          return true;
        }
        if (isModelExpired(state.exportJob)) {
          return true;
        }
        return false;
      }),
    };
  },
});
