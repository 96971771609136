







import {
  DOMO_EXPIRATION_TTL_DAYS,
  getJobDateToCheckExpiry,
  getJobDuration,
  getJobExpiresInDuration,
  isDateExpired,
} from '@/module/api/domo';
import { formatDbDate } from '@/utils';
import { defineComponent, ref } from '@vue/composition-api';
import Vue from 'vue';

const duration = ref('');
const titleText = ref('');
const isExpired = ref(false);
const expiresIn = ref<string | undefined>('');

const getTitleText = (job: any): string => {
  if (!job.startedAt) {
    return '';
  }
  const startedAt = `Started at ${formatDbDate(job.startedAt)}.`;
  if (!job.completedAt) {
    return startedAt;
  }
  return `${startedAt} Completed at ${formatDbDate(job.completedAt)}.`;
};

const setData = (theJob: any): void => {
  if (!theJob) {
    duration.value = '';
    titleText.value = '';
    isExpired.value = false;
    expiresIn.value = '';
    return;
  }
  duration.value = getJobDuration(theJob);
  titleText.value = getTitleText(theJob);
  isExpired.value = isDateExpired(getJobDateToCheckExpiry(theJob));
  expiresIn.value = getJobExpiresInDuration(theJob);
};

export default defineComponent({
  name: 'JobDurationText',
  components: {},
  props: ['job'],
  watch: {
    job: function(newVal: any, oldVal: any): void {
      Vue.$log.debug(`JobDurationText: changed summary from ${oldVal} to ${newVal}`);
      setData(newVal);
    },
  },
  setup(props) {
    setData(props.job);
    return {
      duration,
      titleText,
      isExpired,
      expiresIn,
      DOMO_EXPIRATION_TTL_DAYS,
    };
  },
});
